/*
 *   File : family-request.js
 *   Author URI : https://evoqins.com
 *   Description : Modal for showing new request status. 
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GradientButton, OutlineButton } from "../CTA";
import Icon from "../Icon/icon";

import style from "../../Styles/Component/cancel-sip.module.scss";

const FamilyJoiningRequest = (props) => {
    const { status } = props;
    
    useEffect(() => {
        var my_modal = document.getElementById("family-request-status");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

   

    return (
        <div className={`modal fade ${style.e_sip_modal}`}
            id="family-request-status"
            tabIndex="-1"
            aria-labelledby="payment-status"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content  padding-32px-lr pb-3 ${style.e_content} `}>
                    <Icon icon="close"
                        size={24}
                        data-bs-dismiss="modal"
                        className={`position-absolute cursor-pointer e-modal-close-btn ${style.e_close}`} />
                    <div className="text-center">
                        <img src={ status === true ? require('../../Assets/Images/orders/order-success.svg').default : require('../../Assets/Images/orders/order-failed.svg').default}
                            alt="Pause SIP"
                            width={72}
                            height={72} />
                    </div>
                    <div className="row">
                        <div className="col-12 text-center">
                            <h5 className="color-black e-poppins-semi-bold e-font-16 mt-4">
                               { status === true ? 'Request accepted' : 'Request rejected'} 
                            </h5>
                            <p className="color-eerie-black e-poppins-regular e-font-16 mb-0  text-center">
                                { status === true ? 'Request has been successfully accepted' : 'Request has been rejected'}
                            </p>
                        </div>
                        <div className="mt-4 p d-sm-flex d-block justify-content-center gap-8px mb-3">

                            <GradientButton label="Manage request"
                                cancel="modal"
                                className="e-font-16 padding-70px-lr padding-12px-tb w-mobile-100 mt-sm-0 mt-3" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

FamilyJoiningRequest.defaultProps = {
    close: ()=>{},
}

export default FamilyJoiningRequest